<template>
  <div>
    <fb-header :title="title" />
    <div id="home-nav-page">
      <div class="home-nav-list">
        <router-link :to="link.path" v-for="link in routes" :key="link.path" tag="div" class="router-link-as-div">{{ link.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-nav',
  data() {
    return {
      title: '页面导航',
      routes: [
        {
          path: '/caselist',
          name: '案件列表',
        },
        {
          path: '/ana',
          name: '分析',
        },
        {
          path: '/search',
          name: '大数据',
        },
        {
          path: '/oa',
          name: 'OA',
        },
        {
          path: '/user/mydocument',
          name: '我的文书',
        },
        {
          path: '/lawyerCost',
          name: '计算律师费',
        },
        {
          path: '/dingjuan',
          name: '归档',
        },
      ],
    }
  },
  computed: {},
  methods: {},
  mounted() {},
  created() {},
}
</script>

<style lang="stylus" scoped>
#home-nav-page
  height 100%
  width 100%
  & .home-nav-list
    font-size 16px
    & .router-link-as-div
      padding-left 15px
      font-size 16px
      height 44px
      display flex
      align-items center
</style>
