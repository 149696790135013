var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("fb-header", { attrs: { title: _vm.title } }),
      _c("div", { attrs: { id: "home-nav-page" } }, [
        _c(
          "div",
          { staticClass: "home-nav-list" },
          _vm._l(_vm.routes, function (link) {
            return _c(
              "router-link",
              {
                key: link.path,
                staticClass: "router-link-as-div",
                attrs: { to: link.path, tag: "div" },
              },
              [_vm._v(_vm._s(link.name))]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }